import React from 'react'
import './AboutCard.css'
import Introduction_Icon from '../../assets/SVGs/Introduction Icon.svg'
import Vision_Icon from '../../assets/SVGs/Vision Icon.svg'
import Experties_Icon from '../../assets/SVGs/Experties Icon.svg'
import Overview_Icon from '../../assets/SVGs/Overview Icon.svg'
import Philosophy_Icon from '../../assets/SVGs/Philosophy Icon.svg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Left_Slide_Button_Icon_Mobile_ from '../../assets/SVGs/Left Slide Button Icon (Mobile).svg'
import Right_Slide_Button_Icon_Mobile_ from '../../assets/SVGs/Right Slide Button Icon (Mobile).svg'
import PropTypes from 'prop-types';

export default function AboutCard() {
  return (
    <div>
      <div className='about-cards-section'>
          <div className='card'>
            <div className='icon-div'>
              <img src={Introduction_Icon} />
            </div>
            <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Introduction</h4>
            <div className='paragraph-div'>
              <p style={{ color: '#888787', fontSize: '11px'}}>An investment management company specializing in non-risk trading.</p>
            </div>
          </div>

          <div className='card'>
            <div className='icon-div'>
              <img src={Vision_Icon} />
            </div>
            <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Vision</h4>
            <div className='paragraph-div'>
              <p style={{ color: '#888787', fontSize: '11px'}}>Established with the goal of providing clients with superior investment opportunities. </p>
            </div>
          </div>

          <div className='card'>
            <div className='icon-div'>
              <img src={Experties_Icon} />
            </div>
            <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Expertise</h4>
            <div className='paragraph-div'>
              <p style={{ color: '#888787', fontSize: '11px'}}>We leverage a combination of trading experience, data analysis, and market knowledge. </p>
            </div>
          </div>

          <div className='card'>
            <div className='icon-div'>
              <img src={Overview_Icon} />
            </div>
            <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Risk Management</h4>
            <div className='paragraph-div'>
              <p style={{ color: '#888787', fontSize: '11px'}}>Our team&apos;s core focus is on risk management, market timing, and maximizing client returns.</p>
            </div>
          </div>

          <div className='card'>
            <div className='icon-div'>
              <img src={Philosophy_Icon} />
            </div>
            <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Philosophy</h4>
            <div className='paragraph-div'>
              <p style={{ color: '#888787', fontSize: '11px'}}>Harnessing Risk Management Systems, Embracing Adaptability, and Maintaining Steadfast Composure. </p>
            </div>
          </div>
      </div>
      <CardSlider/>
    </div>
  )
}

const CardSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20%',
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className='about-card-slider'>
      <Slider {...settings}>
        <div className='slider-card'>
          <div className='slider-icon-div'>
            <img src={Introduction_Icon} />
          </div>
          <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Introduction</h4>
          <p style={{ color: '#888787', fontSize: '11px'}}>An investment management company specializing in non-risk trading.</p>
        </div>
        <div className='slider-card'>
          <div className='slider-icon-div'>
            <img src={Vision_Icon} />
          </div>
          <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Vision</h4>
          <p style={{ color: '#888787', fontSize: '11px'}}>Established with the goal of providing clients with superior investment opportunities. </p>
        </div>
        <div className='slider-card'>
          <div className='slider-icon-div'>
            <img src={Experties_Icon} />
          </div>
          <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Expertise</h4>
          <p style={{ color: '#888787', fontSize: '11px'}}>We leverage a combination of trading experience, data analysis, and market knowledge. </p>
        </div>
        <div className='slider-card'>
          <div className='slider-icon-div'>
            <img src={Overview_Icon} />
          </div>
          <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Risk Management</h4>
          <p style={{ color: '#888787', fontSize: '11px'}}>Our teams core focus is on risk management, market timing, and maximizing client returns.</p>
        </div>
        <div className='slider-card'>
          <div className='slider-icon-div'>
            <img src={Philosophy_Icon} />
          </div>
          <h4 style={{ color: '#F618AD', fontWeight: 'bold', margin:'20px' }}>Philosophy</h4>
          <p style={{ color: '#888787', fontSize: '11px'}}>Harnessing Risk Management Systems, Embracing Adaptability, and Maintaining Steadfast Composure. </p>
        </div>
      </Slider>
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
      <img src={Left_Slide_Button_Icon_Mobile_} alt="Previous" />
    </div>
  );
};

CustomPrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next-arrow" onClick={onClick}>
      <img src={Right_Slide_Button_Icon_Mobile_} alt="Next" />
    </div>
  );
};

CustomNextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};