import React from 'react'
import './ProcessCard.css'
import Investment_Process_Arrow_1 from '../../assets/SVGs/Investment Process Arrow 1.svg'
import Create_an_account_Icon from '../../assets/SVGs/Create an account Icon.svg'
import Investment_Process_Arrow_2 from '../../assets/SVGs/Investment Process Arrow 2.svg'
import Connect_Bank_Account_Icon from '../../assets/SVGs/Connect Bank Account Icon.svg'
import Investment_Process_Arrow_3 from '../../assets/SVGs/Investment Process Arrow 3.svg'
import Password_Sharing_Icon from '../../assets/SVGs/Password Sharing Icon.svg'
import Team_Icon from '../../assets/SVGs/Team Icon.svg'

export default function ProcessCard() {
  return (
    <div className='process-div'>
        <div className='process-inner-div'>
            <div className='arrow-div'>
                <img src={Investment_Process_Arrow_1} />
            </div>
            <div className='process-card'>
                <div className='process-icon-div'>
                    <img src={Create_an_account_Icon} />
                </div>
                <h4 style={{ color: '#006FA7', fontWeight: 'bold', margin:'20px', paddingLeft: '25px'  }}>Create an Account</h4>
                <div className='text-div'>
                    <p style={{ color: '#888787', fontSize: '11px'}}>Clients create and verify an account with trusted brokers.</p>
                </div>
            </div>
        </div>

        <div className='process-inner-div'>
            <div className='process-card'>
                <div className='process-icon-div'>
                    <img src={Connect_Bank_Account_Icon} />
                </div>
                <h4 style={{ color: '#006FA7', fontWeight: 'bold', margin:'20px', paddingLeft: '25px' }}>Connect Bank Account</h4>
                <div className='text-div'>
                    <p style={{ color: '#888787', fontSize: '11px'}}>Link the trading account with a bank account and add funds. </p>
                </div>
            </div>
            <div className='arrow-div'>
                <img src={Investment_Process_Arrow_2} />
            </div>
            <div className='mobile-arrow-div' style={{transform: 'rotate(170deg)'}}>
                <img src={Investment_Process_Arrow_1} />
            </div>
        </div>

        <div className='process-inner-div'>
            <div className='arrow-div'>
                <img src={Investment_Process_Arrow_3} />
            </div>
            <div className='mobile-arrow-div' style={{transform: 'rotate(30deg)'}}>
                <img src={Investment_Process_Arrow_2} />
            </div>
            <div className='process-card'>
                <div className='process-icon-div'>
                    <img src={Password_Sharing_Icon} />
                </div>
                <h4 style={{ color: '#006FA7', fontWeight: 'bold', margin:'20px', paddingLeft: '25px' }}>Password Sharing</h4>
                <div className='text-div'>
                    <p style={{ color: '#888787', fontSize: '11px'}}>Share the trading account password with us, ensuring confidentiality.</p>
                </div>
            </div>
        </div>

        <div className='process-inner-div'>
            <div className='process-card'>
                <div className='process-icon-div'>
                    <img src={Team_Icon} />
                </div>
                <h4 style={{ color: '#006FA7', fontWeight: 'bold', margin:'20px', paddingLeft: '25px'  }}>Kick off</h4>
                <div className='text-div'>
                    <p style={{ color: '#888787', fontSize: '11px'}}>Team at Managing Returns trades on your behalf.</p>
                </div>
            </div>
            <div className='mobile-arrow-div' style={{transform: 'rotate(170deg)'}}>
                <img src={Investment_Process_Arrow_3} />
            </div>
        </div>
    </div>
  )
}
