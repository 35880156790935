import React, {useState, useCallback, useRef, useEffect} from 'react'
import './MyHeader.css'
import Header_Logo from '../../assets/SVGs/Header Logo.svg'
import Mobile_Menu_Icon from '../../assets/SVGs/Mobile Menu Icon.svg'
import { HashLink as Link } from 'react-router-hash-link';

export default function MyHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const fragment = window.location.hash;
    const clicked = fragment.slice(1);
    const menuRef = useRef(null);
    const menuIconRef = useRef(null);

    const toggleMenu = useCallback(() => {
      setIsMenuOpen((prev) => !prev)
    }, [])

    const closeMenu = useCallback(() => {
      setIsMenuOpen(false);
    }, []);

    useEffect(() => {
      function handleOutsideClick(event) {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          menuIconRef.current &&
          !menuIconRef.current.contains(event.target)
        ) {
          closeMenu();
        }
      }
  
      document.addEventListener('mousedown', handleOutsideClick);
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [closeMenu]);

    const scrollToTop = useCallback(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const scrollWithOffset = useCallback((e) => {
      const yCoordinate = e.getBoundingClientRect().top + window.scrollY;
      const yOffset = -90; 
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }, [])

    return (
      <div>
        <div className="app-header">
          <div>
            <Link smooth to="/">
              <img src={Header_Logo} alt="Logo" className="logo" onClick={scrollToTop}/>
            </Link>
          </div>
          <div className="buttons-container">
            <Link smooth to="/#about" scroll={scrollWithOffset} className={`header-button ${clicked === 'about' ? 'clicked' : ''}`}>
                {clicked === 'about' && '['}
                <span className={`${clicked === 'about' ? 'clicked-inner' : ''}`}>About Us</span>
                {clicked === 'about' && ']'}
            </Link>
            <Link smooth to="/#client" scroll={scrollWithOffset} type="button" className={`header-button ${clicked === 'client' ? 'clicked' : ''}`}>
                {clicked === 'client' && '['}
                <span className={`${clicked === 'client' ? 'clicked-inner' : ''}`}>Client Segment</span>
                {clicked === 'client' && ']'}
            </Link>
            <Link smooth to="/#investment-process" scroll={scrollWithOffset} type="button" className={`header-button ${clicked === 'investment-process' ? 'clicked' : ''}`}>
                {clicked === 'investment-process' && '['}
                <span className={`${clicked === 'investment-process' ? 'clicked-inner' : ''}`}>Investment Process</span>
                {clicked === 'investment-process' && ']'}
            </Link>
            <Link smooth to="/#faq" scroll={scrollWithOffset} type="button" className={`header-button ${clicked === 'faq' ? 'clicked' : ''}`}>
                {clicked === 'faq' && '['}
                <span className={`${clicked === 'faq' ? 'clicked-inner' : ''}`}>FAQs</span>
                {clicked === 'faq' && ']'}
            </Link>
            <Link smooth to="/#contact" scroll={scrollWithOffset} type="button" className="contact-button header-button">Contact</Link>

          </div>
          <div className='menu-icon-div' ref={menuIconRef}>
            <img src={Mobile_Menu_Icon} onClick={toggleMenu}/>
          </div>
        </div>
        {isMenuOpen ?
          <div className='mobile-menu-div' ref={menuRef}>
            <Link smooth to="/#about" scroll={scrollWithOffset} type="button" className="mobile-header-button" onClick={closeMenu}>About Us</Link>
            <Link smooth to="/#client" scroll={scrollWithOffset} type="button" className="mobile-header-button" onClick={closeMenu}>Client Segment</Link>
            <Link smooth to="/#investment-process" scroll={scrollWithOffset} type="button" className="mobile-header-button" onClick={closeMenu}>Investment Process</Link>
            <Link smooth to="/#faq" scroll={scrollWithOffset} type="button" className="mobile-header-button" onClick={closeMenu}>FAQs</Link>
            <Link smooth to="/#contact" scroll={scrollWithOffset} type="button" className="mobile-header-button" onClick={closeMenu}>Contact</Link>
          </div>
          : ''
        }
      </div>
    )
  }