import React from 'react'
import './Home.css'
import MyHeader from '../components/Header/MyHeader'
import About_Us_Picture from '../assets/SVGs/About Us Picture.svg'
import AboutCard from '../components/AboutCard/AboutCard'
import ClientSegment from '../components/ClientSegment/ClientSegment'
import Investment_Process_Picture from '../assets/SVGs/Investment Process Picture.svg'
import ProcessCard from '../components/ProcessCard/ProcessCard'
import Connect from '../components/Connect/Connect'
import { Element } from 'react-scroll'
import gif from '../assets/GIFs/Header.gif'
import FAQs from '../components/FAQs/FAQs'

export default function Home() {
  return (
    <div>
      <Element id='top'>
       <MyHeader/>
      </Element>
      <div className='slider-section'>
        <img style={{width:'100%'}} src={gif}/>
      </div>
      <Element id='about'>
        <div className='about-section'>
          <div className='about-section1'>
            <img className='process-img' src={About_Us_Picture}/>
          </div>
          <div className='about-section2'>
            <p className='heading' style={{ color: '#F618AD', fontWeight: 'bold'}}>About Us</p>
            <p className='paragraph'>Welcome to Managing Returns, a distinguished investment management company specializing in non-risk trading. We take pride in offering superior investment opportunities to our valued clients.</p>
          </div>
        </div>
        <AboutCard/>
      </Element>
      <Element id="client">
        <ClientSegment/>
      </Element>
      <Element id="investment-process">
        <div className='about-section'>
          <div className='about-section1'>
            <img className='process-img' src={Investment_Process_Picture}/>
          </div>
          <div className='about-section2'>
            <h1 className='heading' style={{ color: '#16C3FF', fontWeight: 'bold' }}>Investment Process</h1>
            <p className='paragraph'>Start your investment journey with confidence and ease. We provide a straightforward and elegant process to help you build wealth effortlessly. </p>
          </div>
        </div>
        <ProcessCard/>
      </Element>
      <Element id="faq">
        <FAQs/>
      </Element>
      <div className='charge-div'> 
        <p className='charge-div-text'>A straightforward 50% of the profits we earn for clients is our fee</p>
      </div>
      <Element id="contact">
        <Connect/>
      </Element>
      <div className='copyright-div'>
        <p style={{color: '#FFFFFF', fontSize:'10px', height: '10px'}}>&copy; Managing Returns. All Rights Reserved.</p>
      </div>
    </div>
  )
}
