import React, { useCallback, useState } from 'react'
import './FAQs.css'
import Faq_Picture from '../../assets/SVGs/Faq Picture.svg'
import faqsData from './FAQs.json'

export default function FAQs() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = useCallback((event) => {
        const clickedIndex = event.currentTarget.getAttribute('data-index');
        const newIndex = parseInt(clickedIndex, 10); // Parse string to integer
        setActiveIndex((prevIndex) => (prevIndex === newIndex ? null : newIndex));
    }, []);

    return (
        <div className='faq_div'>
            <div className='faq_sec1'>
                <div className='faq_heading'>
                    <h1 style={{ color: '#F618AD', fontWeight: 'bold', fontSize: '50px',width:'70%' }}>FAQs</h1>
                </div>
                {faqsData.map((faq) => (
                    <div key={faq.id} className="faq">
                        <div
                        className={`question`}
                        onClick={toggleFAQ}
                        data-index={faq.id}
                        >
                        {faq.question}
                        <span className="toggle-icon">
                            {activeIndex === faq.id ? '-' : '+'}
                        </span>
                        </div>
                        {activeIndex === faq.id && <div className="answer">{faq.answer}</div>}
                    </div>
                ))}
            </div>
            <div className='faq_sec2'>
                <img src={Faq_Picture} className="faq_img"/>
            </div>
        </div>
    )
}
        