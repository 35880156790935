import React, {useState, useCallback} from 'react'
import './Connect.css'
import Let_s_Connect_Picture from '../../assets/SVGs/Let_s_Connect_Picture.svg'
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import { serverRequest } from '../../services/request'

export default function Connect() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [invest, setInvest] = useState('')
    const [message, setMessage] = useState('')
    const [nameerror , setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailvalidError, setEmailvalidError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [validPhone, setvalidPhone] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const currencies = ['USD', 'EUR', 'GBP'];
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
    const [invetmentError, setinvetmentError] = useState(false)

    const handleCurrencyChange = useCallback((event) => {
        setSelectedCurrency(event.target.value);
    }, [])

    const changeName = useCallback((e) => {
        const nameValue = e.target.value.replace(/[^a-z\s]/gi, '');
        setName(nameValue)
    }, [name]);

    const changeEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [email]);

    const changeInvest = useCallback((e) => {
        const investValue = e.target.value.replace(/\D/g, '');
        setInvest(investValue);
    }, [invest]);

    const changeMessage = useCallback((e) => {
        setMessage(e.target.value)
    }, [message]);

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const handleValidation = useCallback((e) => {
        e.preventDefault()
        setNameError(false)
        setEmailError(false)
        setPhoneError(false)
        setEmailvalidError(false)
        setvalidPhone(false)
        setError(false)
        setSuccess(false)
        setinvetmentError(false)
        let check = true;
        if (name == "") {
            setNameError(true)
            check=false
        }
        else if (email == ""){
            setEmailError(true)
            check=false
        }
        else if(!isEmail(email)){
            setEmailvalidError(true)
            check=false
        }
        else if (phone == ""){
            setPhoneError(true)
            check=false
        }
        else if (!isValidPhoneNumber(phone)){
            setvalidPhone(true)
            check = false
        }
        else if(invest && invest < 10000){
            setinvetmentError(true)
            check = false
        }
        if(check == true){
            let data = {name, email, phone,};
            if (invest) {
                data.invest = selectedCurrency + ' ' + invest;
            }
            if (message) {
                data.message = message;
            }
            contactForm(data)
        }
    })

    const contactForm = useCallback(async (data) => {
        try {
            await serverRequest('post', 'managing_email/', false, data);
            setName('');
            setEmail('');
            setPhone('');
            setInvest('');
            setMessage('');
            setSuccess(true);
        } catch (error) {
            setError(true);
            console.error(error);
        }
    },[])


    
  return (
    <div className='connect-div'>
        <div className='connect-section connect-section1'>
            <h1 className='connect-heading' style={{ color: '#F618AD', fontWeight: 'bold', fontSize: '50px'}}>Let&apos;s Invest!</h1>
            <img className='connect-img' src={Let_s_Connect_Picture} />
        </div>
        <div className='connect-section'>
            <div className='input-div'>
                <div className='label-div'>
                    <p style={{color: '#006FA7', fontWeight: 'bold'}}>Name</p>
                    <p style={{color: '#F618AD', fontWeight: 'bold'}}>*</p>
                </div>
                <input className='input-style' type='text' onChange={changeName} value={name}/>
            </div>
            {nameerror ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Name is required</p> : ''}
            <div className='input-div'>
                <div className='label-div'>
                    <p style={{color: '#006FA7', fontWeight: 'bold'}}>Email</p>
                    <p style={{color: '#F618AD', fontWeight: 'bold'}}>*</p>
                </div>
                <input className='input-style' type='email' onChange={changeEmail} value={email}/>
            </div>
            {emailError ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Email is required</p> : ''}
            {emailvalidError ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Enter valid email</p> : ''}
            <div className='input-div'>
                <div className='label-div'>
                    <p style={{color: '#006FA7', fontWeight: 'bold'}}>Phone</p>
                    <p style={{color: '#F618AD', fontWeight: 'bold'}}>*</p>
                </div>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    className='input-style'
                    value={phone}
                    onChange={setPhone}
                    style={{width: '95%'}}
                />
            </div>
            {validPhone ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Enter valid phone number</p> : ''}
            {phoneError ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Phone Number is required</p> : ''}
            <div className='input-div'>
                <div className='label-div'>
                    <p style={{color: '#006FA7', fontWeight: 'bold'}}>Investment Amount</p>
                </div>
                <div className='currency-div'>
                <select id="currency" value={selectedCurrency} onChange={handleCurrencyChange} className='currency'>
                    {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                        {currency}
                    </option>
                    ))}
                </select>
                    <input className='input-style' type='text' onChange={changeInvest} value={invest} style={{marginLeft:'2%'}}/>
                </div>
            </div>
            {invetmentError ? <p style={{color:'#F618AD', marginBottom:'10px', marginTop:'-15px'}}>Investment Amount should be 10k or more.</p> : ''}
            <div className='input-div' style={{height:'80px'}}>
                <div className='label-div'>
                    <p style={{color: '#006FA7', fontWeight: 'bold'}}>Message</p>
                </div>
                <textarea type='text' rows="3" cols="50" className='input-style' onChange={changeMessage} value={message}></textarea>
            </div>
            <button type='submit' className='contact-us-button' onClick={handleValidation}>Send</button>
            {error ? <p style={{color:'#F618AD', marginBottom:'10px'}}>The message couldn&apos;t be sent.</p> : ''}
            {success ? <p style={{color:'#16C3FF', marginBottom:'10px'}}>The Message sent successfully.</p> : ''}
        </div>
    </div>
  )
}
