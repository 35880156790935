import React, { useCallback, useState, useEffect } from 'react'
import './ClientSegment.css'
import Client_Segment_Picture from '../../assets/SVGs/Client Segment Picture.svg'
import High_Risk_Icon from '../../assets/SVGs/High Risk Icon.svg'
import Medium_Risk_Icon from '../../assets/SVGs/Medium Risk Icon.svg'
import Risk_Averse_Icon from '../../assets/SVGs/Risk Averse Icon.svg'
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Slider } from '@mui/material';

export default function ClientSegment() {
    const [selectedButton, setSelectedButton] = useState('averse');
    const [selectedInvestment, setSelectedInvestment] = useState(10000);
    const [selectedGain, setSelectedGain] = useState(2);
    const [gainmin, setgainmin] = useState(2);
    const [gainmax, setgainmax] = useState(3);
    const [simpleInterest, setsimpleInterest] = useState([]);
    const [compoundInterest, setcompoundInterest] = useState([]);
    const [simpleCAGR, setsimpleCAGR] = useState(0);
    const [compoundCAGR, setcompoundCAGR] = useState(0);
    const [simpleAbsoluteReturn, setsimpleAbsoluteReturn] = useState(0);
    const [compoundAbsoluteReturn, setcompoundAbsoluteReturn] = useState(0);
    const [simplefinal, setsimplefinal] = useState(0);
    const [comfinal, setcomfinal] = useState(0);
    const [gainsimple, setgainsimple] = useState(0);
    const [gaincom, setgaincom] = useState(0);
    const [risk, setRisk] = useState('Risk Averse');
    const [riskDescription, setRiskDescription] = useState('Expect a modest but stable monthly growth target of 2-3%, with the maximum potential loss limited to 2-3% of your initial investment.');

    const handleButtonClick = useCallback((buttonId) => {
        setSelectedButton(buttonId);
    }, []);

    const handleAverseButton = useCallback(() => {
        handleButtonClick('averse');
        setgainmin(2)
        setgainmax(3)
        setSelectedGain(2)
        setRisk('Risk Averse')
        setRiskDescription('Expect a modest but stable monthly growth target of 2-3%, with the maximum potential loss limited to 2-3% of your initial investment.')
    }, []);

    const handleMediumButton = useCallback(() => {
        handleButtonClick('medium');
        setgainmin(5)
        setgainmax(7)
        setSelectedGain(5)
        setRisk('Medium Risk')
        setRiskDescription('Anticipate a moderate monthly growth potential ranging from 5-7%, coupled with a maximum potential loss of 5-7% on your principal capital.')
    }, []);

    const handleHighButton = useCallback(() => {
        handleButtonClick('high');
        setgainmin(10)
        setgainmax(12)
        setSelectedGain(10)
        setRisk('High Risk')
        setRiskDescription('Seek substantial returns with a monthly growth target of 10-12%, while being prepared to accept a maximum potential loss of 10-12% on your principal capital.')
    }, []);

    const handleInvestmentChange = useCallback((event) => {
        setSelectedInvestment(event.target.value);
    }, [selectedInvestment]);

    const handleGainChange = useCallback((event) => {
        setSelectedGain(event.target.value);
    }, []);

    const DollarLabelFormat= useCallback((value) => {
        return `$${value}`;
    }, [])

    const PercentLabelFormat= useCallback((value) => {
        return `${value}%`;
    }, [])
    
    useEffect(() => {
        const simpleInterestData = [];
        const compoundInterestData = [];
      
        for (let year = 1; year <= 5; year++) {
          const simpleInterest = (selectedInvestment * selectedGain * year *12) / 100;
          const simpleTotalAmount = selectedInvestment + simpleInterest;
          
          simpleInterestData.push(simpleTotalAmount)
          const r = selectedGain / 100;
          const n = 1;
          const t = year * 12;
      
          const compoundAmount = selectedInvestment * Math.pow(1 + r / n, n * t);

          compoundInterestData.push(compoundAmount);
        }
        const initialInvestment = selectedInvestment;
        const finalSimpleAmount = simpleInterestData[4]; // Total amount after 5 years
        const finalCompoundAmount = compoundInterestData[4]; // Total amount after 5 years
      
        const calculateCAGR = (initialValue, finalValue) => {
          const cagr = Math.pow(finalValue / initialValue, 1 / 5) - 1;
          return cagr * 100;
        };
      
        const cagrSimple = calculateCAGR(initialInvestment, finalSimpleAmount);
        const cagrCompound = calculateCAGR(initialInvestment, finalCompoundAmount);

        setgainsimple(finalSimpleAmount - initialInvestment)
        setgaincom(finalCompoundAmount - initialInvestment)
        setsimplefinal(finalSimpleAmount)
        setcomfinal(finalCompoundAmount)
        setsimpleInterest(simpleInterestData)
        setcompoundInterest(compoundInterestData)
        setsimpleCAGR(cagrSimple)
        setcompoundCAGR(cagrCompound)
        setsimpleAbsoluteReturn(((finalSimpleAmount - initialInvestment) / initialInvestment) * 100)
        setcompoundAbsoluteReturn(((finalCompoundAmount - initialInvestment) / initialInvestment) * 100)
    }, [selectedInvestment, selectedGain]);
      
    const chartdata = {
        labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
        datasets: [
          {
            label: 'Simple',
            backgroundColor: '#F618AD',
            data: simpleInterest,
            maxBarThickness: 40,
          },
          {
            label: 'Compound',
            backgroundColor: '#16C3FF',
            data: compoundInterest,
            maxBarThickness: 40,
          }
        ],
      }
    

    const chartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }, 
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#006FA7',
                    font: {
                        size: 16
                    },
                },
            },
            y: {
                type: 'logarithmic',
                min: 0,
                grid: {
                    display: false
                },
                ticks: {
                    color: '#006FA7',
                    font: {
                        size: 16
                    },
                    stepSize: 10000,
                },
            }
        }
    };
  return (
    <div>
        <div className='main-div first'>
            <div className='section2'>
            <h1 style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '50px',width:'70%' }}>Client Segment</h1>
            <p style={{ color: '#FFFFFF', width:'70%'}}>Our client segments allow us to cater to a diverse range of risk preferences and investment goals, ensuring that our services align with each client&apos;s individual financial strategy. </p>
            </div>
            <div className='section1'>
            <img src={Client_Segment_Picture} className='client-img'/>
            </div>
        </div>
        <div className='main-div second'>
            <button
                className={`button ${selectedButton === 'averse' ? 'selected' : ''}`}
                onClick={handleAverseButton}
                type='button'
            >
                Risk Averse
            </button>
            <div className='mobile-risk-div'>
                <div className='risk-logo-div'>
                    {selectedButton=='high'? <img src={High_Risk_Icon}/> : ''}
                    {selectedButton=='medium'? <img src={Medium_Risk_Icon}/> : ''}
                    {selectedButton=='averse'? <img src={Risk_Averse_Icon}/> : ''}
                </div>
                <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '20px', marginTop:'5%'}}>{risk}</p>
                <p style={{ color: '#888787', fontSize: '14px', width:'80%'}}>{riskDescription}</p>
            </div>
            <button
                className={`button ${selectedButton === 'medium' ? 'selected' : ''}`}
                onClick={handleMediumButton}
                type='button'
            >
                Medium Risk
            </button>
            <button
                className={`button ${selectedButton === 'high' ? 'selected' : ''}`}
                onClick={handleHighButton}
                type='button'
            >
                High Risk
            </button>
            <div className='dropdown-div'>
                <p className='text'>Investment</p>
                <Slider
                    step={1}
                    min={10000}
                    max={50000} 
                    valueLabelDisplay="on"
                    className='slider' 
                    value={selectedInvestment} 
                    onChange={handleInvestmentChange}
                    valueLabelFormat={DollarLabelFormat}
                />
            </div>
            <div className='dropdown-div'>
                <p className='text'>Monthly Gain</p>
                <Slider
                    step={1}
                    min={gainmin}
                    max={gainmax} 
                    valueLabelDisplay="on"
                    className='slider' 
                    value={selectedGain} 
                    onChange={handleGainChange}
                    valueLabelFormat={PercentLabelFormat}
                />
            </div>
        </div>
        <div className='main-div third'>
            <div className='third-card'>
                <div className='card-div'>
                    <div style={{display: 'flex', alignItems:'center'}}>
                        <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '20px'}}>Simple</p>
                        <div className="horizontal-line horizontal-line-magenta"></div>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', lineHeight:'1'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '16px'}}>Final Value</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{simplefinal.toFixed(2)}</p>
                            <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '16px'}}>CAGR Return</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{simpleCAGR.toFixed(2)}%</p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '16px'}}>Gain</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{gainsimple.toFixed(2)}</p>
                            <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '16px'}}>Absolute Return</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{simpleAbsoluteReturn.toFixed(2)}%</p>
                        </div>
                    </div>
                </div>
                <div className='card-div'>
                    <div style={{display: 'flex', alignItems:'center'}}>
                        <p style={{color:'#16C3FF', fontWeight: 'bold', fontSize: '20px'}}>Compound</p>
                        <div className="horizontal-line horizontal-line-blue"></div>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', lineHeight:'1'}}>
                        <div style={{width: '50%'}}>
                            <p style={{color:'#16C3FF', fontWeight: 'bold', fontSize: '16px'}}>Final Value</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{comfinal.toFixed(2)}</p>
                            <p style={{color:'#16C3FF', fontWeight: 'bold', fontSize: '16px'}}>CAGR Return</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{compoundCAGR.toFixed(2)}%</p>
                        </div>
                        <div style={{width: '50%'}}>
                            <p style={{color:'#16C3FF', fontWeight: 'bold', fontSize: '16px'}}>Gain</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{gaincom.toFixed(2)}</p>
                            <p style={{color:'#16C3FF', fontWeight: 'bold', fontSize: '16px'}}>Absolute Return</p>
                            <p style={{ color: '#888787', marginTop:'-10px', fontSize: '16px'}}>{compoundAbsoluteReturn.toFixed(2)}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-div fourth'>
            <div className='risk-div'>
                <div className='risk-logo-div'>
                    {selectedButton=='high'? <img src={High_Risk_Icon}/> : ''}
                    {selectedButton=='medium'? <img src={Medium_Risk_Icon}/> : ''}
                    {selectedButton=='averse'? <img src={Risk_Averse_Icon}/> : ''}
                </div>
                <p style={{color:'#F618AD', fontWeight: 'bold', fontSize: '20px', marginTop:'5%'}}>{risk}</p>
                <p style={{ color: '#888787', fontSize: '14px', width:'80%'}}>{riskDescription}</p>
            </div>
            <div className='chart-div'>
                <div style={{ height : '90%' , width: '90%'}}>
                    <Bar data={chartdata} options={chartoptions} />  
                </div>  
            </div>
        </div>
    </div>
  )
}
