import axios from 'axios';
import TokenService from '../TokenService';

export const serverRequest = async (
  method,
  url,
  isHeader,
  data = {},
  params = {},
  contentType = 'application/json'
) => {
  let headers = {};
  if (isHeader) {
    if (TokenService.getLocalAccessToken()) {
      headers = {
        'Content-Type': contentType,
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
      };
    } else {
      // TODO: check if auth fails
    }
  }
  const config = {
    method,
    url,
    data,
    params,
    headers,
    baseURL: process.env.React_APP_BASE_API || 'https://api.pos.coaldev.com/'
  };
  const response = await axios(config);
  return response;
};
